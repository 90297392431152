@use '@angular/material' as mat;

@mixin topics-detail-popup-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map-get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map-get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map-get($theme, warn), default);
  $warn-color: mat.m2-get-color-from-palette(map-get($theme, warn), default-contrast);

  .subscribe-button-warn {
    background-color: $warn;
    color: $warn-color;
  }

  .subscribe-button-accent {
    background-color: $accent;
    color: $accent-color;
  }
}
