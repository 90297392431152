@use './variables' as *;

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  letter-spacing: normal;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  outline: none;
}

html {
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f8f8;
}

.app-round-button {
  border-radius: 50%;
  color: #b0b0b0;
}

.app-round-button:hover {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button button[aria-expanded='true'] {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button button.cdk-focused.cdk-program-focused {
  background-color: rgba(57, 76, 90, 0);
}

.app-round-button-mv {
  display: inline-block;
  border-radius: 50%;
}

.app-round-button-mv:hover {
  background-color: rgba(57, 76, 90, 0.05);
}

.app-round-button-mv button[aria-expanded='true'] {
  background-color: rgba(57, 76, 90, 0.05);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #d0d0d0;
}

.fade-in {
  animation: fadeIn ease-in-out 0.2s;
  -webkit-animation: fadeIn ease-in-out 0.2s;
  -moz-animation: fadeIn ease-in-out 0.2s;
  -o-animation: fadeIn ease-in-out 0.2s;
  -ms-animation: fadeIn ease-in-out 0.2s;
}

.full-width {
  width: 100%;
}

.full-height,
.full-height-with-overflow,
full-height-with-overflow-y {
  height: 100%;
}

.full-height-with-overflow,
.full-height-with-overflow-y {
  max-height: 100%;
  overflow: auto;
}

.full-height-with-overflow-y {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.full-height-width {
  height: 100%;
  width: 100%;
}

.width-65 {
  width: 65%;
}

.width-20 {
  width: 20%;
}

.layout-visible {
  visibility: visible;
}

.layout-invisible {
  visibility: hidden;
}

.layout-display-none {
  display: none;
}

.layout-display-block-fadein {
  display: block;
  animation: fadein 1s cubic-bezier(0.4, 0.2, 0.59, 0.92);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.regular-background {
  background-color: $light-grey;
}

.regular-1px-line {
  height: 1px;
  border: 0;
  background-color: #c8c8c8;
}

.fullscreen-panel {
  max-width: 100vw !important;
  width: 100vw !important;
  height: 100vh;
}

.layout-master-view-engine-container {
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  flex: 1;
  height: 100%;
}

mat-tab-group,
.mat-mdc-tab-group,
.mat-tab-body-wrapper,
.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.action-separation:last-child,
.action-separation:first-child,
.action-separation + .action-separation {
  display: none;
}

.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 3rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-20 {
  padding: 20px !important;
}

.m-0 {
  margin: 0 !important
}
.m-1 {
  margin: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.m-3 {
  margin: .75rem !important
}

.m-4 {
  margin: 1rem !important
}

.m-6 {
  margin: 1.5rem !important
}

.m-8 {
  margin: 2rem !important
}

.mt-4 {
  margin-top: 1rem !important
}

.ml-4 {
  margin-left: 1rem !important
}

.mr-4 {
  margin-right: 1rem !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: .75rem !important
}

.mb-4 {
  margin-bottom: 1rem !important
}

.mb-6 {
  margin-bottom: 1.5rem !important
}

.mb-7 {
  margin-bottom: 1.75rem !important
}

.mb-8 {
  margin-bottom: 2rem !important
}

.mb-10 {
  margin-bottom: 2.5rem !important
}

.mb-12 {
  margin-bottom: 3rem !important
}

.h-full {
  height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.w-full-auto {
  width: 100%;
  margin: 0 auto;
}

.min-h-full {
  min-height: 100% !important;
}

.max-h-full {
  min-height: 100% !important;
}

.max-w-4xl {
  max-width: 990px !important;
}

@media (min-width: 600px) {
}

@media (min-width: 960px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 1440px) {
  .xl\:max-w-4xl {
    max-width: 1150px !important;
  }
}

@media (max-width: 600px) {
  .sm\:p-4 {
    padding: 1rem !important;
  }
  .sm\:mb-12 {
    margin-bottom: 6rem !important;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem !important;
  }
}
