@use '@angular/material' as mat;
@use '@ag-grid-community/styles/ag-grid' as *;
@use '@ag-grid-community/styles/ag-theme-material' as *;

$aggrid-selection-checkbox-size: 16px;
$aggrid-horizontal-padding: 10px;

@mixin ag-grid-custom-theme($theme) {
  $ag-mat-accent: mat.m2-get-color-from-palette(map-get($theme, accent), default);
  $primary-darker: mat.m2-get-color-from-palette(map-get($theme, primary), darker);
  $orange50: map-get($theme, al-color-palette-orange50);
  $orange100: map-get($theme, al-color-palette-orange100);
  $blue50: map-get($theme, al-color-palette-blue50);
  $blue100: map-get($theme, al-color-palette-blue100);
  $bg-layer-error: map-get($theme, al-color-bg-layer-error);
  $red50: map-get($theme, al-color-palette-red50);
  $red300: map-get($theme, al-color-palette-red300);
  $grey50: map-get($theme, al-color-palette-grey50);
  $kendo-color-on-base: map-get($theme, kendo-color-on-base);
  $light-grey: map-get($theme, al-light-grey);
  $dark-grey: map-get($theme, al-dark-grey);
  $text-white: map-get($theme, al-color-text-white);

  .ag-theme-material {
    --ag-selected-row-background-color: $text-white !important;

    font-size: 12px;
    font-family: Open Sans, sans-serif;

    .ag-header,
    .ag-header-viewport,
    .ag-header-row,
    .ag-header,
    .ag-layout-normal,
    .ag-row,
    .ag-sticky-top-viewport {
      background-color: $light-grey;
    }

    .ag-header-cell,
    .ag-header-group-cell {
      padding-left: $aggrid-horizontal-padding;
      padding-right: $aggrid-horizontal-padding;
    }

    .ag-cell {
      padding-left: calc($aggrid-horizontal-padding - 1px);
      padding-right: calc($aggrid-horizontal-padding - 1px);
    }

    .ag-body-viewport:has(div.ag-pinned-left-cols-container.ag-hidden) .ag-center-cols-viewport,
    .ag-body-viewport:has(div.ag-pinned-left-cols-container:not(.ag-hidden)) .ag-pinned-left-cols-container {
      .ag-row {
        &.ag-row-hover,
        &.ag-row-selected,
        &.ag-row-focus {
          &:before {
            border-left: 6px solid #8ed0ff;
            height: 100%;
            position: absolute;
            content: '';
          }
        }

        &.ag-row-selected,
        &.ag-row-focus {
          &:before {
            border-left-color: #4f78b7;
          }
        }
      }
    }

    .ag-row.ag-row-focus,
    .ag-row-selected,
    .ag-row-hover:before {
      background-color: $text-white;
    }

    .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
      border-top: none !important;
      border-left: none !important;
      border-bottom: none !important;

      &:before {
        border: none !important;
      }
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
      color: $ag-mat-accent;
    }

    .ag-cell {
      &.bg-red {
        background-color: #ffb5bc70;
      }
    }

    .ag-tooltip {
      background-color: $primary-darker;
      color: $text-white;
      max-width: 300px;
    }

    .ag-row {
      &.bg-grey0 {
        &.text-disabled .ag-cell {
          .ag-cell-value,
          .date-cell {
            color: rgba(0, 0, 0, 0.38);
          }

          &:not(.skip-line-through) {
            .ag-cell-value,
            .date-cell {
              @extend .line-through;
            }
          }
        }
      }
    }

    .ag-selection-checkbox,
    .ag-header-select-all {
      .ag-checkbox-input-wrapper {
        font-size: $aggrid-selection-checkbox-size;
        width: $aggrid-selection-checkbox-size;
        height: $aggrid-selection-checkbox-size;
        line-height: 22px;
      }
    }

    .ag-checkbox-input-wrapper,
    .ag-input-field-input.ag-checkbox-input:hover {
      cursor: pointer;
    }

    .ag-cell-wrapper {
      height: 100%;
    }

    .ag-checkbox-input-wrapper::after {
      top: -3px;
    }

    .ag-set-filter-item {
      .ag-checkbox-input-wrapper::after {
        top: 0 !important;
      }

      .ag-checkbox .ag-input-wrapper,
      .ag-radio-button .ag-input-wrapper {
        margin-left: 5px;
      }
    }

    .ag-set-filter-list,
    .ag-filter-no-matches {
      max-height: 8rem !important;
    }

    .ag-set-filter-item {
      text-transform: capitalize;
    }

    [col-id='selection'].ag-header-cell,
    [col-id='selection'].ag-cell {
      padding-left: 15px;
    }

    grid-engine-call-to-action-cell {
      .app-round-button-mv {
        position: absolute;
        top: 0.3rem;
        left: 0.5rem;
      }
    }

    [ref='eHeaderCompWrapper'].ag-header-cell-comp-wrapper {
      grid-engine-call-to-action-header {
        .app-round-button-mv {
          position: relative;
        }
      }
    }

    .ag-header-row-column [col-id='selection'] [role='presentation'] .ag-header-cell-text {
      display: none;
    }

    .ag-row {
      &.ag-row-hover {
        &.published {
          &:before {
            border-left-color: $blue100 !important;
            background: $blue50 !important;
          }
        }

        &.pending {
          &:before {
            border-left-color: $orange100 !important;
            background: $orange50 !important;
          }
        }

        &.has-error {
          &:before {
            border-left-color: $bg-layer-error !important;
            background: $bg-layer-error !important;
          }
        }
      }

      &.bg-grey0 {
        &.bg-orange100 {
          background: $orange100 !important;
        }

        &.bg-blue100 {
          background: $blue100 !important;
        }
      }

      &.bg-orange100 {
        &:before {
          border-left-color: $orange100 !important;
          background-color: $orange50 !important;
        }
      }

      &.bg-blue100 {
        &:before {
          border-left-color: $blue100 !important;
          background-color: $blue50 !important;
        }
      }

      &.has-configuration-error {
        border-left-color: $red300 !important;
        background: $red50 !important;

        &:before {
          border-left-color: $red300 !important;
          background-color: $bg-layer-error !important;
        }

        &.ag-row-hover:before,
        &.ag-row-hover.published,
        &.ag-row-hover.pending {
          border-left-color: $red50 !important;
          background: $bg-layer-error !important;
        }
      }
    }

    grid-engine-header-shell,
    .icon-header-cell,
    .ag-header-group-cell-label,
    .header-shell,
    .header-shell__customHeader,
    .capitalize-first-letter,
    .customHeaderLabel {
      width: inherit;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;

      span {
        width: inherit;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
      }
    }

    .ag-filter-body-wrapper {
      iot-platform-ui-form-group .group {
        flex-flow: column !important;
      }

      iot-platform-ui-form-field {
        margin-right: 0 !important;
        width: 100% !important;
      }

      .iot-platform-ui-form-field {

        .mat-mdc-form-field {
          .mat-mdc-floating-label mat-label {
            font-size: 12px !important;
            color: #9e9e9e;
          }

          &-subscript-wrapper {
            height: 0 !important;
          }
        }

        &__operators {
          .ag-filter-select {
            line-height: 2rem;
            padding: 0 1rem;
            width: calc(100% - 1.5rem);
          }

          .mdc-line-ripple {
            display: none !important;
          }
        }
      }
    }

    div[col-id="ag-Grid-AutoColumn"] {
      .ag-header-cell-text {
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        word-break: break-word;
        color: $dark-grey;
      }
    }

    .ag-row:not(.ag-row-selected) {
      .ag-row-group-inner-renderer-cell__group_leaf {
        background-color: $grey50;
      }
    }

    .ag-row-group-inner-renderer-cell {
      &__group_leaf {
        .ag-row-group-inner-renderer-cell__group_icon {
          color: $kendo-color-on-base !important;
        }
      }

      &--not-readable {
        .ag-row-group-inner-renderer-cell__group_value,
        .ag-row-group-inner-renderer-cell__group_icon {
          color: $text-white;
        }
      }
    }
  }

  .grid-engine-grid-export-dialog {
    &__name-field {
      &.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 27px !important;
      }
    }
  }

  .ag-column-drop-wrapper {
    .ag-column-drop-horizontal {
      width: 100%;
      max-width: calc(100vw - 17rem);
      min-width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      background-color: $light-grey;
      border: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
      &::-webkit-scrollbar {
        height: 4px !important;
      }
    }

    .ag-column-drop-cell-text {
      font-weight: 600;
    }

    .ag-column-drop-list {
      .ag-column-drop-cell {
        &.ag-column-drop-cell--not-readable {
          .ag-icon,
          .ag-column-drop-cell-text {
            color: $text-white !important;
          }
        }
      }
    }
  }

  .ag-checkbox__pinned-left {
    border-right: var(--ag-borders-critical) var(--ag-border-color) !important;
  }

  .grid-engine-grid-manager-popup--default-view {
    &.cdk-drop-list.dd-list.selected-list {
      .dd-item,
      .mat-expansion-panel {
        background-color: $light-grey !important;
      }
    }
  }
}
