////////////
// COLORS //
////////////

$light-grey: #f8f8f8;
$medium-grey: #c8c8c8;
$dark-grey: #394c5a;
$success-green: #e4f4d0;

$al-color-icon-primary: #333333;
$al-color-icon-supporting: #999999;
$al-color-stroke-primary: #B3B3B3;
$al-color-text-primary: $dark-grey;
$al-color-text-secondary: #666666;
$al-color-text-error: #AC0018;
$al-color-bg-layer-error: #FBE5E8;
$al-color-text-white: #ffffff;
$al-color-palette-orange50: #FDF4E5;
$al-color-palette-orange100: #FBE8CC;
$al-color-palette-orange200: #F8D299;
$al-color-palette-orange300: #F4BB66;
$al-color-palette-orange400: #F1A433;
$al-color-palette-orange700: #8E5500;
$al-color-brand-blue: #375F9B;
$al-color-palette-blue50: #E5F1FC;
$al-color-palette-blue100: #CCE3F9;
$al-color-palette-blue300: #66ACEC;
$al-color-palette-red300: #E76678;
$al-color-palette-red400: #bf5f69;
$al-color-palette-red50: #ff00631a;
$al-color-palette-green200: #B1D7A5;
$al-color-palette-green400: #63B04B;
$al-color-palette-wgreen50: #d9e4e3;
$al-color-palette-wgreen100: #D9EFEA;
$al-color-palette-wgreen200: #5a9d9833;
$al-color-palette-wgreen500: #6b9793;
$al-color-palette-wgreen600: #3B6864;
$al-color-palette-wgreen700: #266A5A;
$al-color-palette-dpblue50: #E5EBF5;
$al-color-palette-dpblue900: #000A1F;
$al-color-palette-purple50: #F0EBF2;
$al-color-palette-purple200: #C4B0CC;
$al-color-palette-grey50: #F2F2F2;
$al-color-palette-grey100: #E5E5E5;
$al-color-palette-grey300: #B3B3B3;
$al-color-palette-grey700: #4D4D4D;
$al-color-palette-grey900: #1A1A1A;
$al-color-text-inactive: #CCCCCC;
$al-color-palette-yellow300: #ffee03;
$al-color-palette-yellow500: #EDDD00;
$al-color-palette-yellow600: #BEB100;

$kendo-color-on-base: #212121;

////////////
// LAYOUT //
////////////

$regular-padding: 20px;
$app-border-radius: 0px;
$app-sidenav-width: 250px;
