@use '@angular/material' as mat;
@use './variables' as *;

$font-family: 'Roboto'; // 'Open Sans, sans-serif'

$custom-typography: mat.m2-define-typography-config(
        $font-family: $font-family,
        $button: mat.m2-define-typography-level(
                $font-family: $font-family,
                $font-weight: 500,
                $font-size: 12px,
                $letter-spacing: normal
        )
);

// Override typography for all Angular Material, including mat-base-typography and all components.
// Override the typography in the core CSS.
@include mat.all-component-typographies($custom-typography);
@include mat.core();

:root {
  --mdc-typography-body1-font-weight: 500;
  --mdc-typography-body1-font-size: 12px;
  --mat-menu-item-label-text-size: 12px;
  --mat-menu-item-label-text-weight: 500;
}

.complementary-line {
  font-size: 12px !important;
  color: $medium-grey;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.capitalize-first-letter {
  &:first-letter {
    text-transform: capitalize;
  }
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.overline {
  text-decoration: overline;
}

.no-underline {
  text-decoration: none;
}
