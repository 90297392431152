@use 'sass:map';

@mixin iot-platform-ui-tab($theme) {
  $text-white: map.get($theme, al-color-text-white);

  .iot-platform-ui-tab-group {
    align-items: stretch !important;
    background-color: $text-white;
    border-radius: 8px;
    font-size: 12px;
    line-height: 12px;
    word-break: break-all;
    word-wrap: break-word;
    position: relative;
    padding: 16px;

    .iot-platform-ui-overview-card {
      padding: 0 !important;
    }

    &__image-container {
      position: relative;
      border-radius: 8px;

      .iot-platform-ui-tab-group__edit-image-button {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        color: $text-white;
        z-index: 1;
      }

      &:hover {
        .iot-platform-ui-tab-group__edit-image-button {
          display: block;
        }
      }
    }

    &__image,
    &__icon {
      width: 100%;
      height: 100%;
      max-width: 330px;
      max-height: 220px;
      border-radius: 8px;

      @media (max-width: 500px) {
        max-width: 100% !important;
      }
    }

    &__icon {
      height: 17rem !important;
    }

    &--shadow {
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    }

    .mat-expansion-panel,
    .overview-card {
      background: transparent !important;
      box-shadow: none !important;
    }

    &__header {
      display: grid !important;
      justify-content: center;
      width: 100%;
    }

    &__link {
      &--disabled {
        .mdc-tab__content {
          pointer-events: auto !important;
          cursor: not-allowed !important;
        }
      }
    }

    .mdc-tab__text-label {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      width: inherit;
    }

    &--left,
    &--right {
      .mat-mdc-tab-link .mdc-tab__content {
        width: 100%;
      }
    }

    &--left {
      .mat-mdc-tab-link .mdc-tab__content {
        justify-content: flex-start;
      }
    }

    &--right {
      .mat-mdc-tab-link .mdc-tab__content {
        justify-content: flex-end;
      }

      .mdc-tab__text-label {
        flex-direction: row-reverse;
      }
    }

    &--vertical {
      .iot-platform-ui-tab-group__header {
        justify-content: stretch !important;
      }

      .mat-mdc-tab-nav-bar {
        height: auto;
        margin-top: 16px;

        .mat-mdc-tab-header-pagination {
          display: none;
        }

        .mat-mdc-tab-links {
          display: flex;
          flex-direction: column;

          .mat-mdc-tab-link {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
