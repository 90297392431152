@use '@angular/material' as mat;
@use 'sass:map';

@mixin material-button-toggle-theme($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $warn: mat.m2-get-color-from-palette(map.get($theme, warn), default);

  .mat-button-toggle-appearance-standard {
    color: $accent;
    background-color: $accent-color;

    &.mat-button-toggle-checked {
      color: $accent-color;
      background-color: $accent;
    }

    .mat-button-toggle-label-content {
      line-height: 32px;
      padding: 0 4px;

      .mat-icon {
        font-size: 20px;
      }
    }
  }
}
