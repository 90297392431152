@use '@angular/material' as mat;

@mixin dalia-device-energy-mode-form-theme($theme) {
  .dalia-device-energy-mode-form {
    .mat-mdc-radio-button {
      width: 100%;

      .mdc-form-field {
        width: 100%;

        .mdc-label {
          width: 100%;
        }
      }
    }

    .iot-platform-ui-infos-display-card-property {
      min-width: 143px !important;
    }
  }
}
